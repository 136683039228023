import Component from "../../../../shared/js/common/component";
import Collapse from "../../../../../node_modules/bootstrap/js/index.umd.js";

export default class BusSchedule extends Component {
    static readonly componentName: string = "b-bus-schedule";
    sliderElement: HTMLElement;

    init(): void {
        this.toggleDepartureTimesListener();
    }

    toggleDepartureTimesListener(): void {
        const collapseButtons = this.element.querySelectorAll<HTMLButtonElement>('.bus-stop__toggle-departure-times');

        collapseButtons.forEach(button => {
            button.addEventListener('click', () => {
                const targetId = button.getAttribute('data-target');
                if (targetId) {
                    const targetElement = this.element.querySelector<HTMLElement>(targetId);
                    if (targetElement) {
                        targetElement.classList.toggle('collapse');
                    }
                }
            });
        });
    }

}