import Component from "../../../../shared/js/common/component";
const TextFill = require('textfilljs');

export default class Faq extends Component {
    static readonly componentName: string = "b-faq";

    flipElements: HTMLElement[];
    desktopVisibleFlipElements: HTMLElement[];

    init(): void {
        this.flipElements = Array.from(this.element.getElementsByClassName('flip-container')) as HTMLElement[];
        this.desktopVisibleFlipElements = Array.from(this.element.querySelector('.show-for-medium').getElementsByClassName('flip-container')) as HTMLElement[];

        this.registerEvents();
    }

    registerEvents() {
        this.flipContainer();
        this.loadMoreLess();

        setTimeout(() => {
            if (window.matchMedia("(min-width: 576px)").matches) {
                this.textFillFrontAndBack();
            }
        }, 500);

    }

    flipContainer() {
        this.flipElements.forEach(el => {
            el.addEventListener('click', e => {
                (e.currentTarget as HTMLElement).classList.toggle('flipped');
                if ((e.currentTarget as HTMLElement).classList.contains('flipped')) {
                    let backHeight = (e.currentTarget as HTMLElement).children[0].children[1] as HTMLElement;
                    if (backHeight.offsetHeight > 80) {
                        (e.currentTarget as HTMLElement).style.height = `${backHeight.offsetHeight}px`;
                    }
                } else {
                    let backHeight = (e.currentTarget as HTMLElement).children[0].children[0] as HTMLElement;
                    (e.currentTarget as HTMLElement).style.height = `${backHeight.offsetHeight}px`;
                }
            });
        });
    }

    loadMoreLess() {

        let loadMoreButton = this.element.querySelector('.load-more');
        let loadLessButton = this.element.querySelector('.load-less');
        [loadMoreButton, loadLessButton].forEach(btn => {
            btn.addEventListener('click', () => {
                let copyTextContainer = this.element.querySelector('.b-faq-copytext');
                copyTextContainer.classList.toggle('open');

                loadMoreButton.classList.toggle('hide');
                loadLessButton.classList.toggle('hide');

                this.flipElements.forEach(el => {
                    el.classList.remove('flipped');
                    el.style.height = '80';
                });

                this.textFillFrontAndBack();
            })
        })
    }

    textFillFrontAndBack() {
        TextFill(".b-faq__flip-text",{
            innerTag: 'span',
            maxFontPixels: 38,
            allowOverflow: false,
            autoResize: true,
        });

        TextFill(".b-faq__flipped-text",{
            innerTag: 'p',
            maxFontPixels: 18,
            allowOverflow: false,
            autoResize: true,
        });
    }
}