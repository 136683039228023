/**
 * Import libraries -> use global typescript file
 */

/**
 * Import modules
 */
import CommonApplication from './../../../shared/js/application';

import Benefit from "./bricks/benefit";
import Faq from "./bricks/faq-accordion";
import MobileNavigation from "./bricks/navigation/mobile-navigation";
import Userlike from "../../aob/js/userlike/userlike";
import BusSchedule from "./bricks/bus-schedule";

/**
 * Application class
 */
class Application extends CommonApplication {
    run() {
        super.run();

        Benefit.initialize();
        Faq.initialize();
        MobileNavigation.initialize();
        Userlike.initialize();
        BusSchedule.initialize();

    }

}

export default Application;