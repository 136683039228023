import Component from "../../../../shared/js/common/component";
import bootstrap from "../../../../../node_modules/bootstrap/js/index.umd.js";

export default class Benefit extends Component {
    static readonly componentName: string = "b-benefit";
    sliderElement: HTMLElement;
    sliderItems: HTMLElement;

    init(): void {
        this.sliderElement = this.element.querySelector('.'+Benefit.componentName+'__slider');
        if (window.screen.width < 576) {
            this.initSlider();
        }
    }

    initSlider() {

        new bootstrap.Carousel(this.sliderElement, {
            interval: 2000,
            wrap: true
        })
    }
}