import Component from "../../../../shared/js/common/component";
import {createMessenger, v1} from "@userlike/messenger";

declare global {
    interface Window {
        Cookiebot?: any;
    }
}

export default class Userlike extends Component {

    static readonly componentName: string = "userlike";

    userlikeContainer: HTMLElement|null;
    userlikeWidgetId: string|null;
    userlike;

    init(): void {
        if (window.Cookiebot && window.Cookiebot.consent.marketing) {
            this.initUserlike();
        } else {
            window.addEventListener('CookiebotOnAccept', () => {
                if (window.Cookiebot && window.Cookiebot.consent.marketing) {
                    this.initUserlike();
                }
            }, false);
        }
    }

    async initUserlike() {
        this.userlikeContainer = document.querySelector('[data-test-id="userlike-container"]');
        let userlikeWidgetIdElement: HTMLBodyElement | null = document.querySelector('.userlike');
        this.userlikeWidgetId = userlikeWidgetIdElement?.dataset.userlikeWidgetId || null;

        if(! this.userlikeWidgetId) {
            return;
        }

        this.createApi()
            .then(this.mount)
            .then((api) => {
                if (this.userlikeContainer) {
                    this.userlikeContainer.innerHTML = "Userlike messenger mounted";
                }
            })
            .catch((e) => {
                const message = `Failed to mount messenger: ${e}`;
                console.error(message);
                if (this.userlikeContainer) {
                    this.userlikeContainer.innerHTML = message;
                }
            });

    }

    async createApi() {
        let result = await createMessenger({
            version: 1,
            widgetKey: this.userlikeWidgetId
        });

        if (result.kind === "error") throw new Error(result.error);

        const {api} = result.value;
        if (api === null) {
            throw new Error(
                "api reached end-of-life, please check documentation and upgrade."
            );
        }

        return api;
    }

    async mount(api: v1.Api) {
        const result = await api.mount();
        if (result.kind === "error") throw new Error(result.error);
        return api;
    }

}